var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-toolbar',[_c('v-card-title',[_vm._v("Roles")]),_c('v-spacer'),(_vm.$can('create', 'roles') || _vm.$can('create', 'Superadmin'))?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp,"to":"/company/role/create","data-testid":"btn-create-role"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('DataTableExtended',{ref:"tableRoles",staticClass:"elevation-0 border",attrs:{"loading":_vm.showTableLoader,"headers":_vm.headers,"items":_vm.rolesList,"sortable":"","item-key":"_key","calculate-widths":true,"server-items-length":_vm.rolesCount,"keep":"_key,name,description,is_active,date_create,date_update,permissions,is_private","customSearch":_vm.filterSearch,"data-testid":"table-roles"},on:{"init-table-data":_vm.fetchTableData,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function({ options, updateOptions }){return [_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","data-testid":"filter-search"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","offset-md":6}})],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('SortMobile',{attrs:{"headers":_vm.headers,"options":options},on:{"update:options":updateOptions}}):_vm._e()]}},{key:`item.name`,fn:function({ item }){return [_c('div',{attrs:{"data-testid":"name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.description`,fn:function({ item }){return [_c('div',{attrs:{"data-testid":"description"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:`item.is_active`,fn:function({ item }){return [(_vm.$can('update', 'roles') || _vm.$can('update', 'Superadmin'))?_c('v-chip',{attrs:{"small":"","color":item.is_active
                ? _vm.Pallette.statusChips.active
                : _vm.Pallette.statusChips.notActive,"text-color":"white","data-testid":"status"},on:{"click":function($event){return _vm.updateRoleStatus(item)}}},[_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")]):_vm._e()]}},(_vm.$vuetify.breakpoint.smAndUp)?{key:`item.update`,fn:function({ item }){return [(_vm.$can('update', 'roles') || _vm.$can('update', 'Superadmin'))?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"to":`/company/role/edit/${item._key}`,"color":_vm.Pallette.actionButtons.edit,"dark":"","fab":"","x-small":"","data-testid":"btn-edit-role"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit role")])])],1):_vm._e()]}}:null,(
            !_vm.$vuetify.breakpoint.smAndUp &&
            (_vm.$can('update', 'roles') || _vm.$can('update', 'Superadmin'))
          )?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[(_vm.$can('update', 'roles') || _vm.$can('update', 'Superadmin'))?_c('VerticalButton',{attrs:{"to":`/company/role/edit/${item._key}`,"icon":"mdi-pencil","text":"Edit role","color":_vm.Pallette.actionButtons.edit,"data-testid":"btn-edit-role"}}):_vm._e()],1)],1)]}}:null],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }