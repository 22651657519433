<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Roles</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'roles') || $can('create', 'Superadmin')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/company/role/create"
          data-testid="btn-create-role"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="tableRoles"
          :loading="showTableLoader"
          :headers="headers"
          :items="rolesList"
          sortable
          item-key="_key"
          class="elevation-0 border"
          :calculate-widths="true"
          :server-items-length="rolesCount"
          v-on:init-table-data="fetchTableData"
          keep="_key,name,description,is_active,date_create,date_update,permissions,is_private"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-roles"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="pa-3">
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
              <v-col cols="12" md="3" :offset-md="6"></v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div data-testid="name">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div data-testid="description">
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              v-if="$can('update', 'roles') || $can('update', 'Superadmin')"
              @click="updateRoleStatus(item)"
              small
              :color="
                item.is_active
                  ? Pallette.statusChips.active
                  : Pallette.statusChips.notActive
              "
              text-color="white"
              data-testid="status"
            >
              {{ item.is_active ? "Active" : "Inactive" }}
            </v-chip>
          </template>
          <template
            v-slot:[`item.update`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <div v-if="$can('update', 'roles') || $can('update', 'Superadmin')">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="`/company/role/edit/${item._key}`"
                    :color="Pallette.actionButtons.edit"
                    dark
                    fab
                    x-small
                    v-on="on"
                    data-testid="btn-edit-role"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit role</span>
              </v-tooltip>
            </div>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('update', 'roles') || $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  :to="`/company/role/edit/${item._key}`"
                  v-if="$can('update', 'roles') || $can('update', 'Superadmin')"
                  icon="mdi-pencil"
                  text="Edit role"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-role"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import RolesMixin from "@/mixins/role-mixin.js"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    DataTableExtended,
    VerticalButton,
    SortMobile,
  },

  mixins: [RolesMixin],

  data() {
    return {
      filterSearch: "",
      headers: [
        {
          text: "Role name",
          align: "left",
          value: "name",
          sortable: true,
          width: 160,
        },
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "is_active",
          sortable: true,
          width: 80,
        },
        {
          text: "",
          value: "update",
          sortable: false,
          width: 40,
        },
      ],
    }
  },

  created: function () {
    this.getAllPermissions()
  },

  mounted: function () {
    if (!this.$can("read", "roles")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },

  watch: {
    filterSearch() {
      this.fetchTableData()
    },
  },

  methods: {
    fetchTableData() {
      let self = this
      if (self.$can("read", "roles") || self.$can("read", "Superadmin")) {
        let getParams = self.$refs.tableRoles.getTableServerParams()
        if (self.filterSearch !== "") getParams.search = self.filterSearch
        self.$store.dispatch("getRolesList", { params: getParams })
      }
    },
    updateRoleStatus(item) {
      let self = this
      if (self.$can("update", "roles") || self.$can("update", "Superadmin")) {
        let formData = {
          slug: item._key,
          data: {
            name: item.name,
            is_active: !item.is_active,
            description: item.description,
            permissions: item.permissions,
          },
        }
        self.$store
          .dispatch("updateUserRole", formData)
          .then((response) => {
            self.fetchTableData()
          })
          .catch(({ response }) => {
            console.log("error on updateRoleStatus", response)
          })
      }
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
  },
}
</script>
